import React from 'react';
import { Box, Flex, Text, VStack, Button, HStack, Link } from '@chakra-ui/react'
import { GrLinkedin, GrGithub, GrMail } from 'react-icons/gr';


function App() {
  return (

  <Box flex={1} height={'100vh'} bgColor={'gray.800'}>
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <VStack textAlign={'center'}>

        <Text fontSize={50} textColor={'white'}>
          Guilherme Grasel
        </Text>
        <Text fontSize={35} textColor={'white'}>
          Desenvolvedor
        </Text>

        <HStack>
          <Link href='https://www.linkedin.com/in/ggrasel/' isExternal>
            <Button >
              <GrLinkedin />
            </Button>
          </Link>

          <Link href='https://github.com/ggrasel' isExternal>
            <Button >
              <GrGithub />
            </Button>
          </Link>

          <Link href='mailto:gui@grasel.com' isExternal>
            <Button >
              <GrMail />
            </Button>
          </Link>

         </HStack>

      </VStack>
    </Flex>
  </Box>
  );
}

export default App;
